.animated-text-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
  }
  
  .animated-text {
    position: absolute;
    top: 0%; /* Настройте этот процент для позиционирования выше */
    left: 0;
    right: 0;
    padding: 20px;
    color: white;
    text-align: right;
    animation: slideIn 500ms ease-out forwards;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  