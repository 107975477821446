body, html, #root {
  height: 100%;
  margin: 0;
  background-color:#3f3f3f;
  overflow: hidden; 
}

.App {
  text-align: center; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  width: 200px;
  height: 100vh; /* Высота экрана для полного вертикального отображения */
  background-color: #303030; /* Цвет фона для навигации */
  padding-top: 20px;
  position: fixed; /* Фиксируем навигационную панель */
  top: 0;
  left: 0;
  overflow: auto; /* Добавляем прокрутку, если содержимое превышает высоту экрана */
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  margin-bottom: 3px;
}

nav ul li a {
  text-decoration: none;
  color: #ffffff;
  display: block;
  padding: 10px;
  background-color: #4a4a4a; /* Цвет фона для ссылок */
  border-radius: 0;
}

nav ul li a:hover {
  background-color: #9d0000; /* Цвет фона при наведении */
}
nav ul li.active a {
  background-color: #ff0000; /* Цвет фона для активной вкладки */
}

.content {
  margin-left: 220px; /* Отступ для основного контента */
  padding: 20px;
  height: 100vh;
  overflow: auto; /* Добавляем прокрутку для основного контента */
}

.cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

