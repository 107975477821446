

/* Скрываем кнопки действий по умолчанию, используя visibility и opacity */
.tableRow .actionButtons {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
}

/* Отображаем кнопки действий при наведении */
.tableRow:hover .actionButtons {
  visibility: visible;
  opacity: 1;
}

/* Убедитесь, что ячейки не меняют размер */
.cell {
  padding: 8px;
  height: 40px;
  transition: padding 0.3s ease, height 0.3s ease; /* Добавляем плавный переход для padding и height */
}


.customOutlinedInput {
  border-color: rgb(70, 69, 69) !important; /* Основной цвет обводки */
}

.customOutlinedInput:hover {
  border-color: red !important; /* Цвет обводки при наведении */
}

.customOutlinedInput.Mui-focused {
  border-color: green !important; /* Цвет обводки при фокусе */
}
.table-cell-header {
  border-bottom: solid 3px black !important;
  background: grey !important;
  color: black !important;
  font-weight: bold !important;
  text-align: center !important;
  padding: 8px !important;
}

.table-container {
  max-height: 75vh; /* Ограничиваем высоту */
  overflow: auto; /* Включаем прокрутку */
  -ms-overflow-style: none; /* Для IE и Edge */
  scrollbar-width: none; /* Для Firefox */
}

/* Для Chrome, Safari и других WebKit-браузеров */
.table-container::-webkit-scrollbar {
  display: none; /* Скрываем полосу прокрутки */
}
